<template>
  <div class="app-container">
    <div class="table-container">
      <el-table :data="list" v-loading="listLoading">
        <el-table-column label="Number">
          <template slot-scope="scope">{{ scope.row.versionNumber }}</template>
        </el-table-column>
        <el-table-column label="Name">
          <template slot-scope="scope"> {{ scope.row.versionName }} </template>
        </el-table-column>
        <el-table-column label="Caption">
          <template slot-scope="scope"> {{ scope.row.caption }} </template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="edit(scope.row.versionNumber)">Details/Edit</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { fetchList } from '@/api/androidVersion';

export default {
  name: 'AndroidVersion',
  created() {
    fetchList().then((response) => {
      this.list = response.data;
      this.listLoading = false;
    });
  },
  data() {
    return {
      list: [],
      listLoading: true,
    };
  },
  methods: {
    edit(versionNumber) {
      this.$router.push({
        path: '/androidVersion/editVersion',
        query: { versionNumber },
      });
    },
  },
};
</script>
